import DownloadButtons from '~/components/DownloadButtons'
import useTranslator from '~/lib/useTranslator'

function HomeHeader() {
  const Translator = useTranslator()
  return (
    <>
      <div className="bg-primary-color py-10 px-4">
        <div className="w-full max-w-screen-lg mx-auto flex flex-row items-center gap-8">
          <img
            src="/images/header-image.svg"
            role="presentation"
            width={530}
            height={438}
            className="hidden md:block"
            alt="Header graphic"
          />

          <div className="flex flex-col gap-6 text-center items-center md:items-start md:text-left">
            <h1 className="text-white text-5xl md:text-6xl font-bold">
              {Translator.singular('Where teams grow together')}
            </h1>
            <p className="text-white text-lg">
              <span className="hidden md:block">
                {Translator.singular(
                  'A team includes everybody, from families to coaches, friends and supporters.'
                )}
              </span>
              <span className="block">
                {Translator.singular(
                  'Easily organize and communicate with everybody on your sports team in one free and simple app.'
                )}
              </span>
            </p>
            <DownloadButtons />
            <p>{Translator.singular('Private & Secure • Free to use')}</p>
          </div>
        </div>
      </div>

      <div className="bg-gradient-to-b from-primary-color to-white to-50% from-50%">
        <div className="max-w-screen-lg mx-4 md:mx-auto bg-primary-color-dark py-2 md:py-6 rounded-xl shadow-lg z-10 md:bg-[url('/images/trust-bg.svg')] bg-cover bg-center bg-no-repeat">
          <div className="flex flex-1 flex-col md:flex-row justify-between items-center">
            <div className="text-white md:text-3xl text-center flex flex-1 gap-2 justify-center py-4 bg-[url('/images/trust-bg-mobile.svg')] bg-center md:bg-none bg-no-repeat w-full">
              {Translator.singular('Trusted by')}{' '}
              <strong>
                {Translator.singular('{{ noOfTeams }} Teams', { noOfTeams: '350,000+' })}
              </strong>
            </div>

            <div className="items-center justify-center flex flex-1 gap-4 py-4 bg-[url('/images/trust-bg-mobile.svg')] bg-no-repeat bg-center md:bg-none w-full">
              <svg className="w-28 h-4">
                <use xlinkHref="#totalstars" />
              </svg>
              <div className="text-white md:text-3xl">
                <strong>4.8/5</strong> {Translator.singular('from')} <strong>35K+</strong>{' '}
                {Translator.singular('ratings')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HomeHeader
