import { useLocation } from '@remix-run/react'
import { createContext, useCallback, useContext, useEffect } from 'react'

const context = createContext<{
  trackScreen: (path: string) => void
  trackEvent: (event: string, data?: Record<string, any>) => void
}>({
  trackScreen: () => {},
  trackEvent: () => {},
})

export default function AnalyticsProvider({ children }: { children: React.ReactNode }) {
  const location = useLocation()

  const trackScreen = useCallback((path: string) => {
    fetch('/t', {
      method: 'POST',
      body: JSON.stringify({ type: 'screen', name: path }),
    })
  }, [])

  const trackEvent = useCallback(
    (event: string, data?: Record<string, any>) => {
      fetch('/t', {
        method: 'POST',
        body: JSON.stringify({
          type: 'event',
          event,
          path: location.pathname + location.search,
          data,
        }),
      })
    },
    [location.pathname, location.search]
  )

  useEffect(() => {
    trackScreen(location.pathname + location.search)
  }, [location.pathname, location.search, trackScreen])

  return <context.Provider value={{ trackScreen, trackEvent }}>{children}</context.Provider>
}

export const useAnalytics = () => {
  return useContext(context)
}
