import { Link } from '@remix-run/react'
import useTranslator from '~/lib/useTranslator'
import DownloadButtons from './DownloadButtons'
import InternalLink from './InternalLink'
import useUser from '~/contexts/user/useUser'

export default function Footer() {
  const Translator = useTranslator()
  const user = useUser()

  return (
    <section className="max-w-screen-xl w-full mx-auto pt-12 py-12 my-12 px-4 border-t border-gray-200">
      <div className="flex flex-col gap-6 md:flex-row items-center justify-between md:items-start">
        <div className="flex">
          <InternalLink to="/">
            <img
              src="/images/heja-logo-green.svg"
              width={100}
              height={60}
              className="fill-primary-color"
              alt="Heja logo"
            />
          </InternalLink>
        </div>

        <div className="flex flex-row md:gap-12 justify-between w-full md:w-auto text-lg font-normal">
          <ul className="flex flex-col gap-2">
            <li>
              <InternalLink to="/">{Translator.singular('Home')}</InternalLink>
            </li>
            <li>
              <InternalLink to="/pricing">{Translator.singular('Pricing')}</InternalLink>
            </li>
            <li>
              <InternalLink to="/fundraising">{Translator.singular('Fundraising')}</InternalLink>
            </li>
            <li>
              <InternalLink to="/partners">{Translator.singular('Partners')}</InternalLink>
            </li>
            <li>
              <InternalLink to="/about">{Translator.singular('About')}</InternalLink>
            </li>
            <li>
              <InternalLink to="/about#press">{Translator.singular('Press')}</InternalLink>
            </li>
            <li>
              <Link to="https://jobs.heja.io/">{Translator.singular('Careers')}</Link>
            </li>
          </ul>
          <ul className="flex flex-col gap-2">
            <li>
              <Link to="https://help.heja.io/">{Translator.singular('FAQ')}</Link>
            </li>
            <li>
              <Link to="https://web.heja.io/">{Translator.singular('Desktop login')}</Link>
            </li>
            <li>
              <Link to="/pro">{Translator.singular('Heja Pro')}</Link>
            </li>
            <li>
              {user?._id ? (
                <Link to="/signout">{Translator.singular('Sign out')}</Link>
              ) : (
                <Link to="/signin">{Translator.singular('Sign in')}</Link>
              )}
            </li>
          </ul>
          <ul className="flex flex-col gap-2">
            <li>
              <Link to="https://coach.heja.io/">{Translator.singular('Blog')}</Link>
            </li>
            <li>
              <Link to="https://www.facebook.com/hejaapp">{Translator.singular('Facebook')}</Link>
            </li>
            <li>
              <Link to="https://twitter.com/heja_app">{Translator.singular('Twitter')}</Link>
            </li>
            <li>
              <Link to="https://www.instagram.com/heja.app">
                {Translator.singular('Instagram')}
              </Link>
            </li>
          </ul>
        </div>

        <div className="flex">
          <DownloadButtons />
        </div>
      </div>
      <div className="flex flex-col gap-2 my-12 items-center">
        <small>©{new Date().getFullYear()} Heja. All rights reserved.</small>
        <small>
          <InternalLink className="underline" to="/terms">
            Terms
          </InternalLink>{' '}
          &{' '}
          <InternalLink className="underline" to="/privacy">
            Privacy
          </InternalLink>
        </small>
      </div>
    </section>
  )
}
